import { gql } from '@apollo/client';

export const CANDIDATES_LISTING = gql`
  query GetCandidatesList(
    $page: Int!
    $limit: Int!
    $myCandidates: Boolean
    $privateCandidate: Boolean
    $columnFilters: CandidateColumnFilter
    $searchText: String
  ) {
    allCandidates(
      payload: { page: $page, limit: $limit }
      filters: {
        myCandidates: $myCandidates
        privateCandidate: $privateCandidate
      },
      columnFilters:$columnFilters
      searchText:$searchText
    ) {
      candidates {
        id
        fullName
        jobTitle
        countryCode
        companyName
        status
        source
        city
        stateCode
        idealJobTitle
        idealWorkType
        availability
        isPrivateCandidate
        resume {
          id
          createdAt
        }
        recruiterOwner {
          id
          fullName
        }
        phones {
          id
          phoneNumber
          isPrimary
        }
        emails {
          id
          email
          isPrimary
        }
        recentEmployment {
          id
          companyName
          jobTitle
          workType
          startedAt
          endedAt
        }
      }
      count
    }
  }
`;
export const CANDIDATES_LISTING_FOR_CANDIDATE_PAGE = gql`
  query GetCandidatesList(
    $page: Int!
    $limit: Int!
    $myCandidates: Boolean
    $privateCandidate: Boolean
    $columnFilters: CandidateColumnFilter
    $searchText: String
  ) {
    allCandidates(
      payload: { page: $page, limit: $limit }
      filters: {
        myCandidates: $myCandidates
        privateCandidate: $privateCandidate
      },
      columnFilters:$columnFilters
      searchText:$searchText
    ) {
      candidates {
        id
        fullName
        jobTitle
        countryCode
        companyName
        status
        city
        stateCode
        isPrivateCandidate
        resume {
          id
          createdAt
        }
        phones {
          id
          phoneNumber
          isPrimary
        }
        emails {
          id
          email
          isPrimary
        }
        recruiterOwner {
          id
          fullName
        }
  
      }
      count
    }
  }
`;

export const GET_CANDIDATE_SUBMISSIONS = gql`
  query GetCandidateSubmissions(
    $page: Int!
    $limit: Int!
    $searchText: String
    $candidateId: String
    $jobOrderId: String
  ) {
    candidateOrJobOrderSubmissions(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        candidateId: $candidateId
        jobOrderId: $jobOrderId
      }
    ) {
      submissions {
        id
        resumeUrl
        resumeName
        status
        createdAt
        salary
        salaryType
        targetSalary
        jobApplicant {
          JobAppStatus
          id
          JobOrder {
            id
            jobTitle
            company {
              id
              name
            }
          }
          candidate {
            id
            fullName
          }
        }
        createdBy {
          id
          fullName
        }
        submittedBy {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const GET_CANDIDATE_DETAILS = gql`
  query GetCandidateDetails($id: String!) {
    getCandidate(id: $id) {
      id
      firstName
      middleName
      lastName
      salutation
      status
      streetAddress
      companyName
      address2
      address1
      jobTitle
      summary
      city
      source
      fullName
      stateCode
      postalCode
      countryCode
      availability
      idealJobTitle
      idealWorkType
      salaryType
      salaryMin
      salaryMax
      seniority
      employmentStatus
      companyName
      jobTitle
      twitterUrl
      fbUrl
      instaUrl
      candidateFromDragResume
      requiresSponsorship
      placementWarning
      interviewWarning
      agreementWarning
      resume {
        id
        resumeFileName
        highlightUrl
        fileType
        originalUrl
        pdfVersionUrl
        createdAt
        updatedAt
      }
      candidateEducation {
        id
        startMonth
        endMonth
        startYear
        endYear
        schoolName
        fieldOfStudy
        degreeEarned
      }

      recruiterOwner {
        id
        fullName
      }
      linkedInUrl
      createdAt
      isPrivateCandidate
      emails {
        id
        email
        isPrimary
      }

      phones {
        id
        phoneType
        phoneNumber
        isPrimary
      }
      candidateStats {
        id
        jobAppCount
        interviewCount
        ownershipExpiration
        submissionCount
        daySinceLastAction
        updatedAt
        lastCheckedInRecruiter {
          fullName
          id
        }
      }
      skillsSet {
        id
        skill {
          id
          name
        }
      }
      recentEmployment {
        id
        companyName
        jobTitle
        workType
        startedAt
        endedAt
      }
      candidateShareeWith {
        id
        fullName
        photo
        userTeamName
      }
    }
  }
`;

export const GET_CANDIDATE_DETAILS_WITH_EMPLOYMENT = gql`
  query GetCandidateDetails($id: String!) {
    getCandidate(id: $id) {
      id
      firstName
      middleName
      lastName
      fullName
      salutation
      status
      streetAddress
      companyName
      jobTitle
      summary
      city
      source
      fullName
      stateCode
      postalCode
      countryCode
      availability
      idealJobTitle
      idealWorkType
      salaryType
      salaryMin
      salaryMax
      employmentStatus
      companyName
      jobTitle
      twitterUrl
      fbUrl
      instaUrl
      recentEmployment {
        id
        companyName
        jobTitle
        workType
        startedAt
        endedAt
      }

      requiresSponsorship
      resume {
        id
        resumeFileName
        highlightUrl
        fileType
        originalUrl
        pdfVersionUrl
        createdAt
        updatedAt
      }
      candidateEducation {
        id
        startMonth
        endMonth
        startYear
        endYear
        schoolName
        fieldOfStudy
        degreeEarned
      }

      recruiterOwner {
        id
        fullName
      }
      linkedInUrl
      createdAt
      isPrivateCandidate
      emails {
        id
        email
        isPrimary
      }

      phones {
        id
        phoneType
        phoneNumber
        isPrimary
      }
      candidateStats {
        id
        jobAppCount
        interviewCount
        ownershipExpiration
        submissionCount
        daySinceLastAction
        updatedAt
        lastCheckedInRecruiter {
          fullName
          id
        }
      }
      skillsSet {
        id
        skill {
          id
          name
        }
      }
      candidateShareeWith {
        id
        fullName
        photo
        userTeamName
      }
    }
  }
`;

export const GET_CANDIDATE_DETAILS_SPECIFIC = gql`
  query GetCandidateDetail($id: String!) {
    getCandidate(id: $id) {
      id
      firstName
      middleName
      lastName
      salutation
      status
      streetAddress
      companyName
      jobTitle
      summary
      city
      source
      fullName
      stateCode
      postalCode
      countryCode
      availability
      idealJobTitle
      idealWorkType
      salaryType
      salaryMin
      salaryMax
      employmentStatus
      companyName
      jobTitle
      twitterUrl
      fbUrl
      instaUrl
      requiresSponsorship
      linkedInUrl
      createdAt
      isPrivateCandidate
      emails {
        id
        email
        isPrimary
      }

      phones {
        id
        phoneType
        phoneNumber
        isPrimary
      }
      candidateStats {
        id
        jobAppCount
        interviewCount
        ownershipExpiration
        submissionCount
        daySinceLastAction
        updatedAt
        lastCheckedInRecruiter {
          fullName
          id
        }
      }
      resume {
        id
        resumeFileName
        highlightUrl
        fileType
        originalUrl
        pdfVersionUrl
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CANDIDATE_ATTACHMENTS = gql`
  query GetAttachment($id: String!) {
    getAttachment(id: $id) {
      id
      attachableId
      attachableType
      documentName
      documentUrl
      driveUrl
      createdAt
    }
  }
`;

export const GET_SINGLE_ATTACHMENT = gql`
  query attachment($id: String!) {
    attachment(id: $id) {
      id
      attachableId
      attachableType
      documentName
      documentUrl
      driveUrl
      createdAt
    }
  }
`;

export const CREATE_RESUME_CLONE = gql`
  mutation CreateResumeClone($resumeId: String!) {
    createResumeClone(resumeId: $resumeId) {
      id
      resumeFileName
      originalUrl
      highlightUrl
      scrubUrl
      createdAt
      updatedAt
    }
  }
`;

export const GET_CANDIDATE_WRITEUPS = gql`
  query getWriteUps($id: String!, $page: Int!, $limit: Int!) {
    getWriteUps(id: $id, payload: { page: $page, limit: $limit }) {
      writeUps {
        id
        candidate {
          id
          fullName
        }
        writeUpType
        writeupSummary
        createdAt
        endedAt
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const GET_CANDIDATE_SUMMARY = gql`
  query getCandidateSummary($id: String!) {
    getCandidateSummary(id: $id) {
      id
      ownershipExpiration
      recruitingOwner {
        id
        fullName
        outLookToken
        photo
        firstName
        email
      }
    }
  }
`;

export const REMOVE_OWNER = gql`
  mutation RemoveOwner($candidateId: String!) {
    removeOwner(candidateId: $candidateId) {
      message
      status
    }
  }
`;

export const GET_COMPLETED_SUBMISSION = gql`
  mutation GetCompletedSubmission($candidateId: String!, $jobOrderId: String!) {
    getCompletedSubmission(candidateId: $candidateId, jobOrderId: $jobOrderId) {
      message
      status
    }
  }
`;

export const GET_EMPLOYMENT_HISTORY = gql`
  query getEmploymentHistory($id: String!) {
    getEmploymentHistory(id: $id) {
      id
      companyName
      jobTitle
      workType
      startedAt
      endedAt
      description
      mostRecentExperience
    }
  }
`;

export const DELETE_EMPLOYMENT_HISTORY = gql`
  mutation DeleteEmploymentHistory($id: String!) {
    deleteEmploymentHistory(id: $id) {
      message
      status
    }
  }
`;

export const GET_JOB_APPLICANT = gql`
  query getJobApplicant($id: String!, $page: Int!, $limit: Int!) {
    getJobApplicants(id: $id, payload: { page: $page, limit: $limit }) {
      jobApplicant {
        id
        candidate {
          id
          fullName
        }
        JobOrder {
          id
          jobTitle
          company {
            id
            name
          }
          companyLocation {
            city
            state
          }
        }
        createdAt
        JobAppStatus
        submission {
          id
          targetSalary
          createdAt
          submittedBy {
            fullName
          }
          createdBy {
            fullName
          }
        }
      }
      count
    }
  }
`;

export const GET_JOB_APPLICANT_DROPDOWN_MOCK = gql`
  query getJobApplicant($id: String!, $page: Int!, $limit: Int!) {
    getJobApplicants(id: $id, payload: { page: $page, limit: $limit }) {
      jobApplicant {
        id
        JobOrder {
          id
          jobTitle
        }
        createdAt
      }
      count
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($email: String!) {
    verifyEmailExist(email: $email) {
      exist
      message
    }
  }
`;

export const DELETE_WRITE_UP = gql`
  mutation DeleteWriteUp($id: String!) {
    deleteWriteUp(id: $id) {
      message
      status
    }
  }
`;

export const DELETE_CANDIDATE_EMAIL = gql`
  mutation deleteEmail($id: String!) {
    deleteEmail(id: $id) {
      message
      status
    }
  }
`;

export const DELETE_CANDIDATE_PHONE = gql`
  mutation deletePhone($id: String!) {
    deleteCandidatePhone(id: $id) {
      message
      status
    }
  }
`;

export const CHECK_WRITE_UP_STATE = gql`
  query CheckWriteUpState($id: String!) {
    checkWritUpState(id: $id) {
      initialCall
      meeting
      summary
    }
  }
`;

export const GET_INTERVIEW_EMAILS = gql`
  query InterviewEmail($interviewId: String!) {
    getInterviewEmails(interviewId: $interviewId) {
      interviewEmails {
        id
        body
        subject
        location
        timeZone
        timeZoneId
        additionalFiles {
          fileName
          url
          checked
        }
        interviewEmailDateTime
        isToClient
        includeCandidateWriteup
        includeCandidateResume
        sendCalendarInvite
        toEmails
      }
      count
    }
  }
`;

export const UPDATE_EMPLOYMENT_RECENT = gql`
  mutation UpdateEmploymentRecent(
    $employmentId: String!
    $candidateId: String!
    $recentChecked: Boolean!
  ) {
    employmentRecent(
      employmentId: $employmentId
      candidateId: $candidateId
      recentChecked: $recentChecked
    ) {
      message
      status
    }
  }
`;

export const UPLOAD_RESUME_CANDIDATE = gql`
  mutation ResumeUpload($createResumeDto: CreateResumeDto!) {
    createResume(createResumeDto: $createResumeDto) {
      message
      status
    }
  }
`;

export const DELETE_CANDIDATE_RESUME = gql`
  mutation DeleteCandidateResume($resumeId: String!) {
    deleteResume(resumeId: $resumeId) {
      message
      status
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GetSignedUrl($remoteFilePath: String!) {
    getSignedUrl(remoteFilePath: $remoteFilePath) {
      signedUrl
    }
  }
`;

export const CHECK_EXPIRY_ORIGINAL_RESUME = gql`
  mutation CheckExpiryOriginalResume($resumeId: String!) {
    checkExpiryOriginalResume(resumeId: $resumeId) {
      message
      signedUrl
      status
    }
  }
`;

export const GET_SINGLE_RESUME = gql`
  query GetSingleResume($resumeId: String!) {
    getSingleResume(resumeId: $resumeId) {
      id
      pdfVersionUrl
      resumeFileName
      originalUrl
      highlightUrl
    }
  }
`;

export const GET_CANDIDATE_SUMMARY_WRITEUP = gql`
  query CandidateSummaryWriteUp($candidateId: String!) {
    candidateSummaryWriteUp(candidateId: $candidateId) {
      id
      writeUpType
      writeupSummary
      createdAt
      endedAt
    }
  }
`;

export const GET_CANDIDATE_RESUMES = gql`
  query GetCandidateResumes($candidateId: String!) {
    getCandidateResumes(candidateId: $candidateId) {
      id
      resumeFileName
      originalUrl
      highlightUrl
      scrubUrl
      pdfVersionUrl
      uploadedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_CANDIDATE_RESUMES_CONTENT = gql`
  query getCandidateResumesContent($candidateId: String! $resumeId: String!) {
    getCandidateResumesContent(candidateId: $candidateId resumeId: $resumeId) {
      textContent
    }
  }
`;

export const GET_LATEST_CANDIDATE_RESUME = gql`
  query GetLatestCandidateResume($candidateId: String!) {
    getLatestCandidateResume(candidateId: $candidateId) {
      id
      resumeFileName
      originalUrl
      highlightUrl
      pdfVersionUrl
      scrubUrl
      uploadedAt
      createdAt
      updatedAt
    }
  }
`;

export const ALL_CANDIDATES_ADVANCE_SEARCH = gql`
  query AllCandidatesAdvanceSearch(
    $page: Int!
    $limit: Int!
    $country: String
    $searchText: String
    $excludeSearch:String
    $city: String
    $state: String
    $zipCode: String
    $radius: String
    $skills: [String!]
  ) {
    allCandidatesAdvanceSearch(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        excludeSearch:$excludeSearch
        city: $city
        country: $country
        state: $state
        zipCode: $zipCode
        radius: $radius
        skills: $skills
      }
    ) {
      candidates {
        id
        fullName
        city
        stateCode
        address2
        address1
        countryCode
        jobTitle
        postalCode
        status
        linkedInUrl
        companyName
        geoLocation
        skillsSet {
          id
          skill {
            id
            name
          }
        }
        resume {
          id
          resumeFileName
          highlightUrl
          fileType
          originalUrl
          pdfVersionUrl
          createdAt
          updatedAt
        }
        emails {
          id
          email
          isPrimary
        }
        employmentHistory {
          jobTitle
          companyName
          startedAt
          endedAt
        }
        candidateEducation {
          fieldOfStudy
          schoolName
        }
        phones {
          id
          isPrimary
          phoneNumber
        }
        recruiterOwner {
          firstName
          lastName
        }
      }
      geoLocDiffs
      aggsResult {
        key
        doc_count
      }
      count
    }
  }
`;

export const CREATE_WRITE_UP = gql`
  mutation CreateWriteUp(
    $candidateId: String!
    $writeUpType: String!
    $writeupSummary: String!
  ) {
    createWriteUp(
      createWriteUp: {
        candidateId: $candidateId
        writeUpType: $writeUpType
        writeupSummary: $writeupSummary
      }
    ) {
      message
      status
    }
  }
`;

export const Add_Candidate_Owner = gql`
  mutation addCandidateOwner($AddCandidateOwnerDto: AddCandidateOwnerDto!) {
    addCandidateOwner(addCandidateOwner: $AddCandidateOwnerDto) {
      message
      status
    }
  }
`;

export const EDIT_WRITE_UP = gql`
  mutation EditWriteUp($writeupSummary: String!, $writeupId: String!) {
    editWriteUp(
      editWriteUp: { writeupSummary: $writeupSummary, writeupId: $writeupId }
    ) {
      message
      status
    }
  }
`;

export const CREATE_EMPLOYMENT_HISTORY = gql`
  mutation CreateEmploymentHistory(
    $candidateId: String!
    $companyName: String!
    $workType: String!
    $startedAt: String!
    $endedAt: String
    $jobTitle: String!
    $description: String
    $currentlyWorking: Boolean!
  ) {
    createEmploymentHistory(
      createEmployment: {
        candidateId: $candidateId
        companyName: $companyName
        workType: $workType
        description: $description
        startedAt: $startedAt
        endedAt: $endedAt
        jobTitle: $jobTitle
        currentlyWorking: $currentlyWorking
      }
    ) {
      message
      status
    }
  }
`;

export const EDIT_EMPLOYMENT_HISTORY = gql`
  mutation EditEmploymentHistory(
    $companyName: String!
    $workType: String!
    $startedAt: String!
    $endedAt: String
    $description: String
    $jobTitle: String!
    $currentlyWorking: Boolean!
    $id: String!
    $candidateId: String!
  ) {
    editEmploymentHistory(
      editEmployment: {
        companyName: $companyName
        workType: $workType
        startedAt: $startedAt
        endedAt: $endedAt
        description: $description
        jobTitle: $jobTitle
        currentlyWorking: $currentlyWorking
        id: $id
        candidateId: $candidateId
      }
    ) {
      message
      status
    }
  }
`;

export const CREATE_CANDIDATE = gql`
  mutation CreateCandidate($CreateCandidateDto: CreateCandidateDto!) {
    createCandidate(candidateInput: $CreateCandidateDto) {
      id
      firstName
    }
  }
`;

export const UPDATE_CANDIDATE = gql`
  mutation UpdateCandidate($UpdateCandidateDto: UpdateCandidateDto!) {
    updateCandidate(updateCandidate: $UpdateCandidateDto) {
      message
      status
    }
  }
`;

export const UPDATE_PRIVATE_CANDIDATE_STATUS = gql`
  mutation updatePrivateCandidateStatus(
    $UpdateCandidateDto: UpdateCandidateDto!
  ) {
    updatePrivateCandidateStatus(updateCandidate: $UpdateCandidateDto) {
      message
      status
    }
  }
`;
export const GET_EDUCATION_HISTORY = gql`
  query GetEducationHistory($candidateId: String!) {
    getEducationHistory(candidateId: $candidateId) {
      id
      startMonth
      endMonth
      startYear
      endYear
      schoolName
      fieldOfStudy
      degreeEarned
    }
  }
`;

export const CREATE_EDUCATION_HISTORY = gql`
  mutation CreateEductionHistory($createEduction: CreateEducationHistory!) {
    createEducationHistory(createEduction: $createEduction) {
      status
      message
    }
  }
`;

export const DELETE_EDUCATION_HISTORY = gql`
  mutation CreateEductionHistory($educationId: String!) {
    deleteEducationHistory(educationId: $educationId) {
      message
      status
    }
  }
`;

export const UPDATE_EDUCATION_HISTORY = gql`
  mutation UpdateEductionHistory($updateEducation: UpdateEducationHistory!) {
    updateEducationHistory(updateEducation: $updateEducation) {
      message
      status
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($resumeId: String!) {
    deleteResume(resumeId: $resumeId) {
      message
      status
    }
  }
`;

export const GET_CANDIDATE_SHAREE = gql`
  query GetCandidateSharee($candidateId: String!) {
    getCandidateSharee(candidateId: $candidateId) {
      candidateShareeWith {
        id
        fullName
        photo
        userTeamName
      }
    }
  }
`;

export const CREATE_CANDIDATE_SHAREE = gql`
  mutation CreateCandidateSharee(
    $createCandidateShareeDto: CreateCandidateShareeDto!
  ) {
    createCandidateSharee(createCandidateShareeDto: $createCandidateShareeDto) {
      message
      status
    }
  }
`;

export const DELETE_CANDIDATE_SHAREE = gql`
  mutation DeleteCandidateSharee($candidateId: String!) {
    deleteCandidateSharee(candidateId: $candidateId) {
      message
      status
    }
  }
`;

export const CREATE_SUBMISSION_ATTACHMENT = gql`
  mutation CreateSubmissionAttachment($createSubmissionAttachmentInput: SubmissionAttachmentDto!) {
    createSubmissionAttachment(createSubmissionAttachmentInput: $createSubmissionAttachmentInput) {
      message
      status
    }
  }
`;

export const GET_SUBMISSION_ATTACHMENTS = gql`
  query($submissionId: String!) {
    getSubmissionAttachments(submissionId: $submissionId) {
      attachmentName
      attachmentURL
      attachmentType
    }
  } 
`;