import { gql } from '@apollo/client';

export const GET_ALL_PLACMENTS_ACCOUNTING_DASHBOARD = gql`
  query AllPlacementListAccountingDashboard(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $myPendingActions: Boolean
    $columnFilters: AccountingPlacementColumnFilter!
  ) {
    allPlacementListAccountingDashboard(
      payload: { page: $page, limit: $limit }
      columnFilters:$columnFilters
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        myPendingActions: $myPendingActions
      }
    ) {
      placements {
        id
        invoiceNumber
        jobOrderOfPlacement{
          companyId,
          id
        }
        placementId
        startDateString
        candidateName
        companyName
        paidAtString
        isPaid
        paidAt
        paymentType
        jobTitle
        netCommission
        placementStatus
        candidateFullName
        jobWorkType
        candidateId
        peopleTwoFee
        accountManager {
          fullName
        }
        recruiter {
          id
          fullName
        }
        jobApplicant {
          id
        }
        jobOrder {
          id

          company {
            id
          }
        }
        jobStreet
        jobCity
        jobCountryCode
        totalCommission
        jobStateCode
        candidateCountryCode
        placementType
        candidateEmail
        startDate
        finalEndDate
        createdAt
      }
      count
    }
  }
`;

export const GET_ALL_PLACMENTS_MANAGEMENT_DASHBOARD = gql`
  query AllPlacementListManagementPage(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $myPendingActions: Boolean
    $columnFilters: PlacementColumnFilter!
  ) {
    allPlacementListManagementPage(
      payload: { page: $page, limit: $limit }
      columnFilters:$columnFilters
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        myPendingActions: $myPendingActions
      }
    ) {
      placements {
        id
        placementId
        startDateString
        candidateName
        companyName
        jobTitle
        netCommission
        placementStatus
        isPaid
        candidate {
          id
          fullName
          isPrivateCandidate
        }
        peopleTwoFee
        accountManager {
          id
          fullName
        }
        recruiter {
          id
          fullName
        }
        jobOrder {
          id
          workType

          company {
            id
            name
          }
        }
        jobApplicant {
          id
        }
        jobStreet
        jobCity
        jobCountryCode
        totalCommission
        jobStateCode
        placementType
        candidateEmail
        startDate
        finalEndDate
        createdAt
      }
      count
    }
  }
`;

export const GET_BILLING_CONTACT = gql`
  query GetBillingContact($jobOrderId: String!) {
    billingContact(jobOrderId: $jobOrderId) {
      id
      contact {
        id
        fullName
        firstName
        jobTitle
        lastName
        companyLocation {
          id
          streetAddress
          city
          state
          country
          zipCode
        }
        contactPhone {
          id
          phoneType
          number
          isPrimary
        }
        contactEmail {
          id
          email
          isPrimary
        }
        companyLocation {
          id
          streetAddress
        }
      }
    }
  }
`;

export const GET_PLACEMENT_BY_ID = gql`
  query GetPlacementById($id: String!) {
    getPlacementById(id: $id) {
      id
      isPaid
      placementId
      companyFirstPlacement
      statusNote
      approved
      invoiceNumber
      currentPlacementStep
      placementNote
      noteToAccounting
      companyName
      placementType
      placementStatus
      startDate
      startDateString
      netCommission
      feeStructured
      estimateEndDate
      replacementTerm
      payableTerm
      totalCommission
      adjustmentsReason
      adjustmentTotal
      assignmentDuration
      estimatedRecruiterProfit
      estimatedLessPayrollBurdenPercenatge
      id
      candidateName
      source
      overtimeBillRate
      billRate
      payRate
      markupPercentage
      estimatedLessPayrollBurden
      insuranceCost
      peopleTwoFee
      salaryAmount
      candidateId
      candidateFullName
      candidateFirstName
      candidateLastName
      candidateCompanyName
      candidateJobTitle
      candidateEmail
      candidateStreet
      candidateAddressOne
      candidateAddressTwo
      candidateCity
      candidateCountryCode
      candidatePostalCode
      candidateStateCode
      candidatePhone
      salaryType
      estimateEndDate
      jobTitle
      jobAllowRemote
      jobCompanyLocationId
      jobWorkType
      jobStateCode
      jobStreet
      jobCity
      jobCountryCode
      jobPostalCode
      commissionType
      placementEndDate
      jobworkplaceTypes
      jobOrderOfPlacement {
        id
        company {
          id
        }
      }
      jobOrder {
        id
        workplaceTypes
        company {
          id
          name
          billBoxTag
          ownerId
        }
      }
      placementCcInvoiceTo{
        id
        companyContact {
          id
          fullName
          contactEmail {
            id
            email
            isPrimary
          }
        }
      }
      placementMainContact {
        id
        companyContact {
          id
          fullName
          contactEmail {
            id
            email
            isPrimary
          }
        }
      }
      placementBillingContact {
        id
        companyContact {
          id
          fullName
          jobTitle
          contactEmail {
            id
            email
            isPrimary
          }
        }
      }
      placementReportToContact {
        id
        companyContact {
          id
          fullName
          contactEmail {
            id
            email
            isPrimary
          }
        }
      }
      rejectOrApproveBy {
        id
        fullName
      }
      agreement {
        id
        nickName
      }
      candidate {
        id
        firstName
        lastName
        address1
        address2
        source
      }
      jobApplicant {
        id
      }
      recruiter {
        id
        fullName
      }
      accountManager {
        id
        fullName
        email
      }
      managerOfAM {
        id
        fullName
      }
      placementContacts {
        id
        companyContact {
          id
          fullName
        }
        contactType
      }
      replacementId
    }
  }
`;

export const PLACEMENT_SPLITTING_BY_ID = gql`
  query PlacementSplitting($placementId: String!) {
    placementSplitting(placementId: $placementId) {
      id
      splitType
      splitPercentage
      user {
        id
        fullName
      }
    }
  }
`;
export const PAID_AT_UPDATE = gql`
  mutation paidUpdateDetails($paidUpdateDetails: paidUpdateDetails!) {
    paidUpdateDetails(paidUpdateDetails: $paidUpdateDetails) {
      message
      status
    }
  }
`;

export const ADD_INVOICE_NUMBER = gql`
  mutation addInvoiceNumber($placementId: String!, $invoiceNumber: String!) {
    addInvoiceNumber(placementId: $placementId, invoiceNumber: $invoiceNumber) {
      message
      status
    }
  }
`;