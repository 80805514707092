import { useLazyQuery } from '@apollo/client';
import { Key, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Eclipse_Img from '../../../assets/images/text-ellipse.svg';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import {
  downloadSignedUrlFile,
  formatDateUS,
  handleHtmlToPdfDownload,
} from '../../../utils/commonFn';
import {
  COMPANY_DETAILS_ROUTE,
  PAGE_LIMIT,
  UPDATE_CANDIDATE_ROUTE,
  UPDATE_JOB_ORDER_ROUTE,
  VIEW_CANDIDATE_ROUTE,
  VIEW_JOB_ORDER_ROUTE,
} from '../../../utils/constant';
import { moneyFormatter, pushInHistory } from '../../../utils/helper';
import { GET_CANDIDATE_WRITEUP } from '../../candidate-submission/gql';
import '../candidate.scss';
import { GET_CANDIDATE_SUBMISSIONS } from '../gql';

const DownloadCustomButton = ({ candidateId, buttonText, candidateName, resumeUrl }: { candidateId: string, buttonText: string, candidateName: string, resumeUrl?: string }) => {
  const [getWriteUp, { loading }] = useLazyQuery(
    GET_CANDIDATE_WRITEUP
  );
  const [downloading, setDownloading] = useState<boolean>(false)
  const handleDownloadWriteup = async (summary: string, name: string) => {
    setDownloading(true)
    await handleHtmlToPdfDownload(
      summary,
      `${name}-writeup`, setDownloading
    );
    setDownloading(false)
  };

  const onWriteUpDownload = async () => {
    const { data } = await getWriteUp({
      variables: {
        candidateId,
        companyOwner: true,
      },
    });
    if (data?.checkCandidateWriteup?.writeupSummary) {
      handleDownloadWriteup(data?.checkCandidateWriteup?.writeupSummary, candidateName);
    }
  };

  const downloadResume = async () => {
    setDownloading(true);
    await downloadSignedUrlFile(
      resumeUrl!,
      `${candidateName}-resume.pdf`, setDownloading
    );
    setDownloading(false);
  }
  return (
    <CustomButton
      className="buttonGenericStyle m-2"
      loading={loading || downloading}
      buttonClick={() => resumeUrl ? downloadResume() : onWriteUpDownload()}
      buttonText={buttonText}
      disabled={loading || downloading}
    />
  )
}

const SubmissionHistoryCandidate = ({
  candidateId,
  jobOrderId,
  location,
  isEdit,
}: {
  candidateId?: string;
  jobOrderId?: string;
  location: any;
  isEdit?: boolean;
}) => {
  const page = parseInt('1');
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(5);

  const [getCandidateSubmissions, { data: candidateSubmissionsData }] =
    useLazyQuery(GET_CANDIDATE_SUBMISSIONS);



  const isJobOrderRoute =
    location.pathname.includes(VIEW_JOB_ORDER_ROUTE) ||
    location.pathname.includes(UPDATE_JOB_ORDER_ROUTE);
  const backURL = isJobOrderRoute
    ? !!isEdit
      ? `${UPDATE_JOB_ORDER_ROUTE}/${jobOrderId}`
      : `${VIEW_JOB_ORDER_ROUTE}/${jobOrderId}`
    : !!isEdit
      ? `${UPDATE_CANDIDATE_ROUTE}/${candidateId}`
      : `${VIEW_CANDIDATE_ROUTE}/${candidateId}`;

  useEffect(() => {
    if (candidateId || jobOrderId) {
      getCandidateSubmissions({
        variables: {
          limit,
          page: currentPage,
          candidateId: candidateId || '',
          searchText: '',
          jobOrderId: jobOrderId || '',
        },
      });
    }

    // eslint-disable-next-line
  }, [candidateId, jobOrderId, currentPage, limit]);



  return (
    <div>
      {candidateSubmissionsData?.candidateOrJobOrderSubmissions?.submissions?.map(
        (
          item: {
            salary: number;
            salaryType: string;
            createdBy: { fullName: string };
            createdAt: string | Date;
            status: string;
            targetSalary: string;
            resumeUrl: string;
            resumeName: string;
            submittedBy: { fullName: string };
            jobApplicant: {
              JobAppStatus: string;
              JobOrder: {
                jobTitle: string;
                id: string;
                company: { name: string; id: string };
              };
              candidate: {
                id: string;
                fullName: string;
              };
            };
            submissionAttachments: any[];
          },
          index: Key
        ) => (
          <div className="application-status" key={index}>
            <div>
              <small className="mb-1">
                <strong>Salary:</strong> {moneyFormatter(item?.salary || 0)}/
                {item?.salaryType === 'HOURLY' ? 'Hour' : 'Annual'}
              </small>

              <div>
                <small className="mb-1">
                  <strong>Job Title:</strong>

                  {!!item?.jobApplicant?.JobOrder?.jobTitle ? (
                    <span
                      className={`${!isJobOrderRoute && 'route-link'}`}
                      onClick={() => {
                        !isJobOrderRoute &&
                          history.push({
                            pathname: `${VIEW_JOB_ORDER_ROUTE}/${item?.jobApplicant?.JobOrder?.id}`,
                            state: {
                              backUrl: backURL,
                              searchParameters: location.search,
                            },
                          });
                        pushInHistory(backURL, location.search);
                      }}
                    >
                      {item?.jobApplicant?.JobOrder?.jobTitle}
                    </span>
                  ) : (
                    '--'
                  )}
                </small>
              </div>

              {!candidateId && (
                <div>
                  <small className="mb-1">
                    <strong>Candidate Name:</strong>{' '}
                    <span
                      className="route-link text-bluelink text-xs"
                      onClick={() => {
                        history.push({
                          pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.jobApplicant?.candidate?.id}`,
                          state: {
                            backUrl: backURL,
                            searchParameters: location.search,
                          },
                        });

                        pushInHistory(backURL, location.search);
                      }}
                    >
                      {item?.jobApplicant?.candidate?.fullName}
                    </span>
                  </small>
                </div>
              )}

              <div>
                <small className="mb-1">
                  <strong>Company name (Client):</strong>{' '}
                  <span
                    className="route-link"
                    onClick={() => {
                      history.push({
                        pathname: `${COMPANY_DETAILS_ROUTE}/${item?.jobApplicant?.JobOrder?.company?.id}`,
                        state: {
                          backUrl: backURL,
                          searchParameters: location.search,
                        },
                      });

                      pushInHistory(backURL, location.search);
                    }}
                  >
                    {item?.jobApplicant?.JobOrder?.company?.name}
                  </span>
                </small>
              </div>

              <small className="mb-1">
                <strong>Target Salary:</strong> {item?.targetSalary}
              </small>
              <div>
                <small className="mb-1">
                  <strong>Submitted By:</strong>{' '}
                  {item?.submittedBy?.fullName || '--'}
                </small>
              </div>
              <div>
                <small className="mb-1">
                  <strong>Created By:</strong>{' '}
                  {item?.createdBy?.fullName || '--'}
                  <img src={Eclipse_Img} alt="delete" className="mx-1" />
                  <span>
                    {item.createdAt ? formatDateUS(item?.createdAt) : '--'}{' '}
                    {new Date(item?.createdAt).toLocaleTimeString()}{' '}
                  </span>
                </small>
              </div>

              <div>
                <small className="mb-1">
                  <strong>Job App Status:</strong>{' '}
                  <Badge
                    color={
                      item?.jobApplicant?.JobAppStatus === 'DRAFT'
                        ? 'primary'
                        : item?.jobApplicant?.JobAppStatus ===
                          'LOST_TO_COMPETITOR' ||
                          item?.jobApplicant?.JobAppStatus === 'HOLD' ||
                          item?.jobApplicant?.JobAppStatus === 'CLOSE'
                          ? 'warning'
                          : item?.jobApplicant?.JobAppStatus === 'CANCELLED'
                            ? 'danger'
                            : item?.jobApplicant?.JobAppStatus === 'FILLED_BY_US' ||
                              item?.jobApplicant?.JobAppStatus ===
                              'FILLED_BY_CLIENT' ||
                              item?.jobApplicant?.JobAppStatus === 'ACTIVE'
                              ? 'success'
                              : 'info'
                    }
                    className="text-capitalize"
                  >
                    {item?.jobApplicant?.JobAppStatus}
                  </Badge>
                </small>
              </div>
            </div>

            <div className="d-flex align-items-baseline justify-content-center flex-wrap">
              <Badge
                color={item?.status === 'DRAFT' ? 'warning' : 'success'}
                className="text-capitalize font-weight-bold"
              >
                {item?.status}
              </Badge>

              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <DownloadCustomButton
                  buttonText='Download Writeup'
                  candidateId={item.jobApplicant.candidate.id} candidateName={item?.jobApplicant?.candidate?.fullName} />
                <DownloadCustomButton buttonText="Download Resume" resumeUrl={item?.resumeUrl}
                  candidateId={item.jobApplicant.candidate.id} candidateName={item?.jobApplicant?.candidate?.fullName} />
              </div>
            </div>
          </div>
        )
      )}

      {!candidateSubmissionsData?.candidateOrJobOrderSubmissions?.submissions
        ?.length && (
          <div className="empty-table-td">
            <NoDataFound text="No Submission History Found!" />
          </div>
        )}

      {candidateSubmissionsData?.candidateOrJobOrderSubmissions?.submissions
        ?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              candidateSubmissionsData?.candidateOrJobOrderSubmissions?.count /
              limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  candidateSubmissionsData?.candidateOrJobOrderSubmissions
                    ?.count
                )}{' '}
                of{' '}
                {
                  candidateSubmissionsData?.candidateOrJobOrderSubmissions
                    ?.count
                }
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SubmissionHistoryCandidate;
